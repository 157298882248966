<div class="book-viewer" *ngIf="sharedBookProject">
  <header class="book-header">
    <div class="book-title-section">
      <h1 class="book-title">{{ sharedBookProject.title }}</h1>
      <p class="book-author">By {{ sharedBookProject.author }}</p>
    </div>

    <div class="book-content-section">
      <div class="book-cover">
        <img [src]="bookCoverUrl" alt="Book cover" onerror="this.remove()">
      </div>
      <div class="book-description" *ngIf="sharedBookProject.description">
        <p>{{ sharedBookProject.description }}</p>
      </div>
    </div>

    <div id="google_translate_element" class="translate-element"></div>
    <div class="reader-apps" *ngIf="isMobile">
      <p class="reader-label">Listen with Elevenlabs Reader:</p>
      <div class="mobile-actions">
        <button (click)="openElevenLabsReader()" class="open-in-app-btn">
          Open in Elevenlabs Reader
        </button>
      </div>
    </div>
  </header>

  <div class="book-content">
    <div class="chapters-nav">
      <ul>
        <li *ngFor="let chapter of sharedBookProject.chapters; let i = index">
          <a (click)="scrollToChapter($event, i)" [class.active]="currentChapter === i">{{ chapter.title }}</a>
        </li>
      </ul>
    </div>

    <div class="chapters-content">
      <div class="chapter" *ngFor="let chapter of sharedBookProject.chapters; let i = index" [id]="'chapter-' + i">
        <h2 class="chapter-title">{{ chapter.title }}</h2>
        <div class="chapter-content">
          <p *ngFor="let paragraph of splitIntoParagraphs(chapter.fullText)" [innerHTML]="preserveNewlines(paragraph)"></p>
        </div>
      </div>
    </div>
  </div>
</div>
