import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {AuthGuard} from './core/guards/auth.guard';
import {LayoutComponent} from './layouts/layout.component';
import {Page404Component} from './extrapages/page404/page404.component';
import {TermsComponent} from "./pages/terms/terms.component";
import {PrivacyComponent} from "./pages/privacy/privacy.component";
import {LandingLayoutComponent} from "./landing/layout/landing-layout.component";
import {BookProjectViewerComponent} from "./pages/share/book-project-viewer/book-project-viewer.component";
import {bookProjectResolver} from "./pages/share/book-project-viewer/book-project.resolver";

const routes: Routes = [
  {path: 'terms-of-service', component: TermsComponent, data: { title: 'Terms of service' }},
  {path: 'privacy-policy', component: PrivacyComponent, data: { title: 'Privacy policy' }},
  {path: 'account', component: LandingLayoutComponent, loadChildren: () => import('./account/account.module').then(m => m.AccountModule)},
  {
    path: 'book-project/:id',
    component: BookProjectViewerComponent,
    resolve: {
      bookProject: bookProjectResolver
    },
    data: { title: 'Book Project' }
  },
  {
    path: 'dashboard',
    component: LayoutComponent,
    loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'pages',
    loadChildren: () => import('./extrapages/extrapages.module').then(m => m.ExtrapagesModule),
    canActivate: [AuthGuard]
  },
  {
    path: '',
    component: LandingLayoutComponent,
    loadChildren: () => import('./landing/landing.module').then(m => m.LandingModule)
  },
  {path: '**', component: Page404Component},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'top'})],
  exports: [RouterModule]
})

export class AppRoutingModule {
}
