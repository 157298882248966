import { inject } from '@angular/core';
import { ResolveFn, Router } from '@angular/router';
import { catchError, EMPTY } from 'rxjs';
import { BookProjectApiService } from '../../../core/services/book-project-api.service';
import { SharedBookProjectDto } from '../../../core/models/book-project.model';

export const bookProjectResolver: ResolveFn<SharedBookProjectDto> = (route) => {
  const bookProjectService = inject(BookProjectApiService);
  const router = inject(Router);
  const projectId = route.paramMap.get('id');

  if (!projectId) {
    router.navigate(['/404']);
    return EMPTY;
  }

  return bookProjectService.getSharedBookProject(projectId).pipe(
    catchError(() => {
      router.navigate(['/404']);
      return EMPTY;
    })
  );
};
